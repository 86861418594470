import React from "react";
import img from "../images/qr-code.png";
import { Link } from "react-router-dom";

const Review = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='review' >

        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
          <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={img} />
          </div>
          <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

            <h3 className="text-3xl  text-blue-900 font-bold">Enjoyed our services? Leave us a Google Review!</h3>
            <div>
              <p className='my-3 text-xl text-gray-600 font-semibold'>Your feedback helps us improve and helps others find us.</p>
            </div>

            <div>
              <p className='my-3 text-xl text-gray-600 font-semibold'>It's quick and easy! Or click the button below:</p>
            </div>
            <a
              className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
              href="https://www.google.com/search?q=qp+cleaners&rlz=1C1RXQR_enCA1031CA1031&oq=qp+cleaners&aqs=chrome.0.69i59l2j69i64j0i512l2j69i60l2j69i61.999j0j7&sourceid=chrome&ie=UTF-8#lrd=0x537170d560a92717:0x4c41a39d71ae74d2,3,,,,"
              target="_blank" // Opens the link in a new tab/window
            >
              Leave a review
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Review;