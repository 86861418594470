import React from "react";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";

import drycleaning from "../images/clients/drycleaning.png";
import repair from "../images/clients/repair.jpg";
import alterations from "../images/clients/alterations.jpg";
import more from "../images/clients/more.png";

const Services = () => {
  return (
    <section data-aos="zoom-in-down">
    <div className="my-4 py-4">
      <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
        Our Services
      </h2>

      <div className="flex justify-center">
        <div className="w-24 border-b-4 border-blue-900"></div>
      </div>
      <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
        We are deeply committed to ensuring customer satisfaction.
      </h2>
    </div>

    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="card img"
              className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
              src={drycleaning}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">
              Dry Cleaning
            </h2>
            <p className="text-md font-medium">
              We specialize in expert garment care. Our state-of-the-art
              facility, experienced team, and eco-friendly methods ensure
              your clothing stays fresh and lasts longer. Convenient online
              scheduling and fast service make us your trusted clothing care
              partner.
            </p>
          </div>
        </div>

        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="card img"
              className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
              src={alterations}
            />
            <h2 className="font-semibold my-4 text-2xl text-center">
              Alterations
            </h2>
            <p className="text-md font-medium">
              We are the experts in tailoring and alterations. Our skilled
              team transforms your garments to achieve the perfect fit and
              style. Whether it's hemming, resizing, or custom tailoring, we
              bring your clothing dreams to life. Elevate your wardrobe with
              precision alterations from us.
            </p>
          </div>
        </div>

        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="card img"
              className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
              src={repair}
            />
            <h2 className="font-semibold my-4 text-2xl text-center ">
              Repairs
            </h2>
            <p className="text-md font-medium">
              We excel in garment restoration. Our skilled artisans are
              experts in mending rips, tears, and snags with precision. From
              fixing zippers to patching holes, we're dedicated to
              rejuvenating your wardrobe staples. Trust us to make your
              clothes look as good as new, so you can wear them with
              confidence.
            </p>
          </div>
        </div>

        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
          <div className="m-2 text-justify text-sm">
            <img
              alt="card img"
              className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
              src={more}
            />
            <h2 className="font-semibold my-4 text-2xl text-center ">
              Many More
            </h2>
            <p className="text-md font-medium">
              We offer expert garment repair, precise wedding dress
              alterations, steaming, and cater to special requests. Your
              clothing, our craftsmanship, and your satisfaction – it's what
              we're all about.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services;